import type { UnleashToggles } from '@/domain';

export const brands: { [key: string]: { id: string; name: string; contactUrl: string; termsUrl: string; unleashId: string } } = {
    novasol: {
        id: 'novasol',
        name: 'Novasol',
        contactUrl: 'https://www.novasol.no/hjelp',
        termsUrl: 'https://www.novasol.no/hjelp/generelle-vilkar-og-leiebetingelser',
        unleashId: 'novasolDirectBooking',
    },
    interhome: {
        id: 'interhome',
        name: 'Interhome',
        contactUrl: 'https://www.interhome.no/kundeservice/',
        termsUrl: 'https://www.interhome.no/kundeservice/bestillingsvilkar/',
        unleashId: 'interhomeDirectBooking',
    },
    dancenter: {
        id: 'dancenter',
        name: 'DanCenter',
        contactUrl: 'https://www.dancenter.no/kontakt.html',
        termsUrl: 'https://www.dancenter.no/info/leiebetingelser/',
        unleashId: 'dancenterDirectBooking',
    },
    belvilla: {
        id: 'belvilla',
        name: 'Belvilla',
        contactUrl: 'https://www.belvilla.com/contact-us',
        termsUrl: 'https://www.belvilla.com/service',
        unleashId: 'belvillaDirectBooking',
    },
    sologstrand: {
        id: 'sologstrand',
        name: 'Sol og Strand',
        contactUrl: 'https://www.sologstrand.no/kundeservice',
        termsUrl: 'https://www.sologstrand.no/praktiske-info/bestillinger',
        unleashId: 'sologstrandDirectBooking',
    },
    danland: {
        id: 'danland',
        name: 'Danland',
        contactUrl: 'https://www.danland.no/callback.html',
        termsUrl: 'https://www.danland.no/info/leiebetingelser/',
        unleashId: 'danlandDirectBooking',
    },
    tui: {
        id: 'tui',
        name: 'Belvilla',
        contactUrl: 'https://www.belvilla.com/contact-us',
        termsUrl: 'https://www.belvilla.com/service#headingTop',
        unleashId: 'tuiDirectBooking',
    },
    yourrentals: {
        id: 'yourrentals',
        name: 'Your.Rentals',
        contactUrl: 'mailto:bookings@your.rentals',
        termsUrl: 'https://s3.eu-central-1.amazonaws.com/yourrentals/yr-rental-term-for-guest.pdf',
        unleashId: 'yourrentalsDirectBooking',
    },
    norefjellhytta: {
        id: 'norefjellhytta',
        // They have changed their brand, but the ids are kept for now, as changing that would mean a larger
        // refactoring, probably including changing this in the database too.
        name: 'Unike Fjellhytter',
        contactUrl: 'mailto:utleie@unikefjellhytter.no',
        termsUrl: 'https://unikefjellhytter.no/bestilling-og-betaling/',
        unleashId: 'norefjellhyttaDirectBooking',
    },
    cabinliving: {
        id: 'cabinliving',
        name: 'Cabin Living',
        contactUrl: 'mailto:info@cabinliving.no',
        termsUrl: 'https://cabinliving.no/vilkar/',
        unleashId: 'cabinlivingDirectBooking',
    },
    campaya: {
        id: 'campaya',
        name: 'Campaya',
        contactUrl: 'mailto:placeholder@place.holder',
        termsUrl: 'https://finn.no/reise/',
        unleashId: 'campayaDirectBooking',
    },
};

export const directBookingBrandsList = () => Object.values(brands);

export const isDirectBookingForBrandEnabled = (id: string, unleash: Partial<UnleashToggles>) => {
    const matchedBrand = directBookingBrandsList().find((brand) => brand.id === id);

    if (!matchedBrand) return false;
    return unleash[matchedBrand?.unleashId] ?? false; // return false if not an import brand or if unleash toggle is not setup
};
